<template>
  <div class="mc-container">
    <div class="d-flex flex-column">
      <b-button variant="primary mb-2 ml-auto" @click="inputVisible = !inputVisible">
        Cadastrar novos créditos
      </b-button>
      <b-form-group v-if="inputVisible">
        <label for="quantity-key">Quantidade de chaves</label>
        <b-form-input
          id="quantity-key"
          type="number"
          autocomplete="off"
          v-model="quantity"
        />

        <b-button variant="primary" class="mt-2" @click="createGlaucoCredits">
          Salvar
        </b-button>
      </b-form-group>
      <div class="d-flex flex-column">
        <b-table
          id="glauco-credits-table"
          :fields="fields"
          :items="items"
        />

        <b-pagination
          class="ml-auto"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="glauco-credits-table"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
name: 'ManageCodes',
  props: {
    clinic: Object
  },
  async created() {
    if (this.clinic?.id) await this.getGlaucoCredits()
  },
  data () {
    return {
      quantity: 0,
      accesCodes: [],
      fields: [
        {
        key: 'clinic.name',
        label: 'Clínica',
        sortable: true
        },
        {
          key: 'credits',
          label: 'Créditos',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Criado em',
        }
      ],
      items: [],
      inputVisible: false,
      currentPage: 1,
      perPage: 20,
      rows: 0,
    }
  },
  methods: {
    async getGlaucoCredits() {
      try {
        const { data } = await this.api.getGlaucoCredits(this.clinic.id, this.currentPage)
        this.items = data.data.map(item => ({
          ...item,
          created_at: this.moment(item.created_at).format('DD/MM/YYYY'),
        }))
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.rows = data.total
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async createGlaucoCredits() {
      if (this.quantity <= 0) return this.$toast.warning('Informe a quantidade de créditos válida')
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.createGlaucoCredits({
          clinic_id: this.clinic.id,
          credits: this.quantity,
        })

        this.items.push({
          ...response.data,
          clinic: this.clinic,
          created_at: this.moment(response.data.created_at).format('DD/MM/YYYY'),
        })
        this.$toast.success('Créditos criados com sucesso')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  },
  watch: {
    clinic: {
      async handler(newVal) {
        if (newVal?.id) await this.getGlaucoCredits()
      },
      immediate: true,
      deep: true,
    }
  },
}
</script>

<style lang="scss">
.mc-container {
  min-height: 100vh;
  padding: 40px 20px;
}
</style>
